<template>
  <div class="grid gap-4 sm:cols-2">
    <NInput
      input="outline"
      placeholder="This is the outline variant"
    />

    <NInput
      input="solid"
      placeholder="This is the solid variant"
    />

    <NInput
      input="~"
      placeholder="This is the base input"
    />
  </div>
</template>
