<template>
  <div>
    <NSeparator
      size="4xl"
    />
    <NSeparator
      size="40px"
    />
    <NSeparator
      size="9xl"
    />
  </div>
</template>
