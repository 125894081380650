<template>
  <div class="flex gap-4">
    <NToggle
      label="i-radix-icons-font-bold"
    />

    <NToggle
      leading="i-radix-icons-font-bold"
      :icon="false"
      :square="false"
      label="Bold"
    />
  </div>
</template>
