<template>
  <div class="flex gap-4">
    <NButton
      label="Normal"
    />

    <NButton
      block
      label="Block"
    />
  </div>
</template>
