<template>
  <div class="flex flex-wrap items-center gap-4">
    <NSwitch
      size="xs"
    />

    <NSwitch
      size="sm"
    />

    <NSwitch
      size="md"
    />

    <NSwitch
      size="lg"
    />

    <NSwitch
      size="xl"
    />

    <NSwitch
      size=".25in"
    />

    <NSwitch
      size="0.7cm"
    />
  </div>
</template>
