<template>
  <div class="flex flex-col space-y-4">
    <NProgress
      indeterminate
      progress="primary"
    />

    <NProgress
      indeterminate
      progress="error"
    />

    <NProgress
      indeterminate
      progress="indigo"
    />

    <NProgress
      indeterminate
      progress="orange"
    />
  </div>
</template>
