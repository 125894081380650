<template>
  <div class="flex flex-col gap-4">
    <NCard
      title="outline-purple"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
      card="outline-purple"
    />
    <NCard
      title="soft-info"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
      card="soft-info"
    />
  </div>
</template>
