<template>
  <div class="flex flex-wrap items-center gap-4" size="2xl">
    <NIcon name="i-info" class="text-info" />

    <NIcon name="i-success" class="text-success" />

    <NIcon name="i-warning" class="text-warning" />

    <NIcon name="i-error" class="text-error" />

    <NIcon name="i-loading" class="animate-spin text-muted" />

    <NIcon name="i-close" />

    <NIcon name="i-check" />
  </div>
</template>
