import revive_payload_client_STGnSepQbZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5._me7dgel52ieeo5khkhmslexija/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eO4Pys4vmA from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5._me7dgel52ieeo5khkhmslexija/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_laiUaLxAZ9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5._me7dgel52ieeo5khkhmslexija/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_QRU566GCCu from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.4_magicast@0.3.5_rollup@4.27.4_vite@6.0.1_@types+node@22.10.1_jiti@2.4.0_xmc47jbbyzingcxd2dmdn432o4/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_iVO8kiLyQq from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5._me7dgel52ieeo5khkhmslexija/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_4LVH5PaIkh from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5._me7dgel52ieeo5khkhmslexija/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7faPxll2kP from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5._me7dgel52ieeo5khkhmslexija/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_BgggZ4c19Z from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5._me7dgel52ieeo5khkhmslexija/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/docs/.nuxt/components.plugin.mjs";
import prefetch_client_6BAIFKR5IL from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5._me7dgel52ieeo5khkhmslexija/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_uIMhJYlcgg from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.8.2_magicast@0.3.5_rollup@4.27.4_vite@6.0.1_@types+node@22.10.1_jiti@2.4.0_sass@_awnpq63napdiyqvuubpfll74pe/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import documentDriven_LCoA06AsPQ from "/vercel/path0/node_modules/.pnpm/@nuxt+content@2.13.4_ioredis@5.4.1_magicast@0.3.5_nuxt@3.14.1592_@parcel+watcher@2.5.0_@types_xptnxwz34b3do4r5mvshnk6nwe/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import plugin_client_JGUISdi9wD from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.27.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/docs/.nuxt/unocss.mjs";
import theme_client_8QYLtQWjlY from "/vercel/path0/packages/nuxt/src/runtime/plugins/theme.client.ts";
import analytics_client_mtqVw3YRXk from "/vercel/path0/docs/plugins/analytics.client.ts";
export default [
  revive_payload_client_STGnSepQbZ,
  unhead_eO4Pys4vmA,
  router_laiUaLxAZ9,
  _0_siteConfig_QRU566GCCu,
  payload_client_iVO8kiLyQq,
  navigation_repaint_client_4LVH5PaIkh,
  check_outdated_build_client_7faPxll2kP,
  chunk_reload_client_BgggZ4c19Z,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6BAIFKR5IL,
  plugin_uIMhJYlcgg,
  documentDriven_LCoA06AsPQ,
  plugin_client_JGUISdi9wD,
  unocss_MzCDxu9LMj,
  theme_client_8QYLtQWjlY,
  analytics_client_mtqVw3YRXk
]