<template>
  <div class="flex">
    <NInput
      placeholder="Search"
      trailing="i-heroicons-chat-bubble-left-right-20-solid"
      class="pl-12"
    >
      <template #leading>
        <NAvatar
          avatar="~"
          square="6"
          src="https://avatars.githubusercontent.com/u/33350692?s=400&u=49395c835e8197ae2ee42ca02c95e828d8f64239&v=4"
        />
      </template>
    </NInput>
  </div>
</template>
