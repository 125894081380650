<template>
  <div class="flex flex-wrap items-center gap-4">
    <NAvatar
      src="/images/avatar.png"
      rounded="none"
    />

    <NAvatar
      src="/images/avatar.png"
      rounded="lg"
    />

    <NAvatar
      src="/images/avatar.png"
      rounded="t-none b-full"
    />

    <NAvatar
      src="/images/avatar.png"
      rounded="l-none r-full"
    />

    <NAvatar
      src="/images/avatar.png"
      rounded="t-none r-full b-full l-none"
    />
  </div>
</template>
