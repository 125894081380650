import { default as _91_46_46_46slug_93vTCpP7XoA5Meta } from "/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.0_@types+node@22.10.1_@unocss+preset-wind@0.64.1_@_2ma2w7iia4xbsgs3besiasw5ba/node_modules/@una-ui/content/pages/[...slug].vue?macro=true";
import { default as indexxuXGT14PMbMeta } from "/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.0_@types+node@22.10.1_@unocss+preset-wind@0.64.1_@_2ma2w7iia4xbsgs3besiasw5ba/node_modules/@una-ui/content/pages/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.0_@types+node@22.10.1_@unocss+preset-wind@0.64.1_@_2ma2w7iia4xbsgs3besiasw5ba/node_modules/@una-ui/content/pages/[...slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.0_@types+node@22.10.1_@unocss+preset-wind@0.64.1_@_2ma2w7iia4xbsgs3besiasw5ba/node_modules/@una-ui/content/pages/index.vue")
  }
]